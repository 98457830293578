import '../sass/project.scss';

let initTooltips = function () {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
}

let initToggleFlags = function () {
    /* Toggle Feature Flags */
    const TOGGLE_URL = '/user/flags/'
    const toggleFlag = async function (flag, op) {
        let csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value;
        await fetch(TOGGLE_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({
                flag: flag,
                op: op,
            })
        });
    };

    document.querySelectorAll('[data-toggle="flag"]').forEach(function (element) {
        element.addEventListener('click', async function (evt) {
            let dataset = evt.target.dataset;
            let action;
            if (dataset.op === "input") {
                action = evt.target.checked ? "set" : "unset";
            } else if (dataset.op === "inverse-input") {
                action = evt.target.checked ? "unset" : "set";
            } else {
                action = dataset.op;
            }
            await toggleFlag(dataset.flag, action);
        })
    });
}

let initGraphs = async function () {
    let getThemeColor = function () {
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? '#adb5bd' : '#212529'
    }
    Chart.defaults.font.size = 16;
    Chart.defaults.color = getThemeColor();

    document.querySelectorAll('.graph').forEach(function (element) {
        let chart = new Chart(element.querySelector("canvas"), {
            type: element.dataset.graphType
        });
    });


    const GRAPH_DATA_URL = '/dashboard-graphs/'
    fetch(GRAPH_DATA_URL, {
        method: 'GET', headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => response.json())
        .then((chartData) => {
            document.querySelectorAll('.graph').forEach(function (element) {
                let data = chartData.graphs[element.dataset.graphId];

                element.querySelector(".card-header").textContent = data.title;

                let tooltipCallback = data.options?.plugins?.tooltip?.callbacks?.label
                if (tooltipCallback) {
                    data.options.plugins.tooltip.callbacks.label = new Function(tooltipCallback.arguments, tooltipCallback.body);
                }
                let chart = Chart.getChart(element.querySelector("canvas"));
                element.classList.remove('graph-loading');
                element.querySelector('.card-body').classList.remove('placeholder');
                chart.options = data.options;
                chart.data.labels = data.data.labels;
                chart.data.datasets = data.data.datasets;
                chart.update();
            });
        });

    window.addEventListener('theme-changed', function (event) {
        for (let id in Chart.instances) {
            let instance = Chart.instances[id];

            let color = getThemeColor();

            Chart.defaults.color = color
            instance.options.plugins.legend.labels.color = color
            for (const [key, scale] of Object.entries(instance.options.scales)) {
                scale.ticks.color = color;
                scale.title.color = color;
            }

            instance.update()
        }
    });

}

window.addEventListener('DOMContentLoaded', () => {
    initTooltips();
    initToggleFlags();
    initGraphs();
});
